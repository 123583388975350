// Load app modules
import router from "@/router";
import store from "@/store";

// Load external modules
import axios from "axios";

// Variables
import { API_URL } from "@/config/url";

// Default headers
let headers = {};

// Load user account
const userData = localStorage.getItem("user");

if (userData)
  try {
    const user = JSON.parse(userData);
    // Add JWT token into header
    headers = { Authorization: `Bearer ${user.accessToken}` };
  } catch (err) {
    console.error("[AXIOS] Unable to parse localstorage", err);
  }

const HTTP = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers,
});

export const getRefreshToken = () => {
  let userDataStr = localStorage.getItem("user");

  if (!userDataStr) return null;

  let userData;
  try {
    userData = JSON.parse(userDataStr);
  } catch (e) {
    return "";
  }

  return userData.refreshToken;
};
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

HTTP.interceptors.request.use(
  async (config) => {
    // Load user account
    const userData = localStorage.getItem("user");

    if (userData)
      try {
        const user = JSON.parse(userData);
        // Add JWT token into header
        config.headers = {
          Authorization: `Bearer ${user.accessToken}`,
        };
      } catch (err) {
        console.error("[AXIOS] Unable to parse localstorage", err);
      }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.config.url !== "auth/refresh-token" &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();
      if (refreshToken && refreshToken !== "") {
        let responseIs400 = false;
        await HTTP.post("auth/refresh-token", { refreshToken })
          .then((response) => {
            store.dispatch("validateLogin", response.data);
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${response.data.accessToken}`;
            HTTP.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.data.accessToken}`;
          })
          .catch((err) => {
            responseIs400 = err.response.status === 400;

            if (err.response.status === 401) {
              alert(
                "Pour des raisons de sécurité, vous avez été déconnecté de votre compte Kermony"
              );
            }
          });

        if (responseIs400) {
          // Quickwin request in //
          for (let i = 0; i < 10; i++) {
            if (
              originalRequest.headers["Authorization"] !==
              HTTP.defaults.headers.common["Authorization"]
            ) {
              break;
            }
            await sleep(500);
          }
          originalRequest.headers["Authorization"] =
            HTTP.defaults.headers.common["Authorization"];
        }

        return HTTP(originalRequest);
      }
    }

    // If un-logged
    if (error.response && error.response.status === 401) {
      // Call logout
      store.dispatch("logout");

      // Redirect to login
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);

export default HTTP;
