<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99976 12L10.9998 14L14.9998 10M7.94611 4.24972L6.2517 4.23704C5.13545 4.22868 4.22843 5.1357 4.23679 6.25195L4.24947 7.94636C4.25352 8.48716 4.03838 9.00655 3.65311 9.38609L2.44602 10.5753C1.6508 11.3587 1.6508 12.6414 2.44602 13.4248L3.65311 14.6139C4.03838 14.9935 4.25352 15.5129 4.24947 16.0537L4.23679 17.7481C4.22843 18.8643 5.13545 19.7713 6.2517 19.763L7.94611 19.7503C8.48691 19.7463 9.0063 19.9614 9.38584 20.3467L10.5751 21.5538C11.3585 22.349 12.6412 22.349 13.4246 21.5538L14.6137 20.3467C14.9933 19.9614 15.5127 19.7463 16.0535 19.7503L17.7479 19.763C18.8641 19.7713 19.7711 18.8643 19.7628 17.7481L19.7501 16.0537C19.7461 15.5129 19.9612 14.9935 20.3465 14.6139L21.5536 13.4248C22.3488 12.6414 22.3488 11.3587 21.5536 10.5753L20.3465 9.38609C19.9612 9.00655 19.7461 8.48716 19.7501 7.94636L19.7628 6.25195C19.7711 5.1357 18.8641 4.22868 17.7479 4.23704L16.0535 4.24972C15.5127 4.25377 14.9933 4.03863 14.6137 3.65336L13.4246 2.44627C12.6412 1.65105 11.3584 1.65105 10.5751 2.44627L9.38584 3.65336C9.0063 4.03863 8.48691 4.25377 7.94611 4.24972Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconBadge",
  props: ['stroke']
}
</script>

<style scoped>

</style>