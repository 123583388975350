<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 9C5.10457 9 6 8.10457 6 7C6 5.89543 5.10457 5 4 5C2.89543 5 2 5.89543 2 7C2 8.10457 2.89543 9 4 9Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 9C21.1046 9 22 8.10457 22 7C22 5.89543 21.1046 5 20 5C18.8954 5 18 5.89543 18 7C18 8.10457 18.8954 9 20 9Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 19C21.1046 19 22 18.1046 22 17C22 15.8954 21.1046 15 20 15C18.8954 15 18 15.8954 18 17C18 18.1046 18.8954 19 20 19Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 7H6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 7C8.65685 7 10 8.34315 10 10V15C10 16.1046 10.8954 17 12 17H18" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconBranch",
  props: ['stroke']
}
</script>

<style scoped>

</style>