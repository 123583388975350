<template>
  <div class="sidebar">
    <div class="top">
      <img src="@/assets/logo.png" />
    </div>

    <div class="separator"></div>

    <div class="nav">
      <router-link
        :to="{ name: tab.routeName }"
        v-for="tab in tabs"
        :key="tab.label"
      >
        <div
          class="item"
          v-bind:class="isActiveMenu(tab.routeName.split('.')[0])"
        >
          <Icon size="thin" :name="tab.icon" color="white" /><span>{{
            tab.label
          }}</span>
        </div>
      </router-link>
      <!--
      <div class="item"><img src="@/assets/icon/thin/branch.svg" /><span>Ma Transmission</span></div>
      <div class="item"><img src="@/assets/icon/thin/financial.svg" /><span>Mes Actifs Financiers</span></div>
      <div class="item"><img src="@/assets/icon/thin/euro.svg" /><span>Mes Biens</span></div>
      <div class="item"><img src="@/assets/icon/thin/lock.svg" /><span>Mon Coffre-fort</span></div>
      <div class="item"><img src="@/assets/icon/thin/logo.svg" /><span>Kermony & Moi</span></div>
      -->
    </div>

    <div class="separator separator-bottom"></div>

    <div class="bottom">
      <div class="item">
        <a
          class="sidebar-link"
          href="https://kermony-office.com/"
          target="_blank"
          >À propos</a
        >
      </div>
      <div class="item">
        <a
          class="sidebar-link"
          href="mailto:masentinelle@kermony-office.com"
          target="_blank"
          >Contact</a
        >
      </div>
      <div class="item">
        <a class="sidebar-link" href="https://calendly.com/d/gsv-mf6-9c2/ma-sentinelle-kermony-45-min" target="_blank"
          >Prendre RDV</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/components/Icons/Icon";

export default {
  name: "Sidebar.vue",
  components: { Icon },
  data() {
    return {
      routeName: this.$route.name,
      tabs: [
        {
          label: "Mon Dashboard",
          icon: "dashboard",
          routeName: "dashboard",
        },
        {
          label: "Ma Famille",
          icon: "users",
          routeName: "family.view",
        },
        {
          label: "Ma Transmission",
          icon: "branch",
          routeName: "transmission",
        },
        {
          label: "Mes Actifs Financiers",
          icon: "financial",
          routeName: "my-financial-assets",
        },
        {
          label: "Mes Biens",
          icon: "euro",
          routeName: "valuable-objects",
        },
        {
          label: "Mes Documents",
          icon: "lock",
          routeName: "coffre-fort",
        },
        {
          label: "Kermony & Moi",
          icon: "logo",
          routeName: "kermony-et-moi",
        },
      ],
    };
  },

  watch: {
    $route: "currentRoute",
  },

  computed: {
    ...mapState(["user", "status"]),
  },

  methods: {
    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name;
      });
    },

    getAvatarLetter() {
      if (this.user && this.user.customerName)
        return this.user.customerName.charAt(0).toUpperCase();

      return "?";
    },

    isActiveMenu: function (itemName) {
      const routeName = this.routeName;

      if (!routeName) return false;

      return { "is-selected": routeName.includes(itemName) };
    },
  },
};
</script>

<style scoped>
.sidebar-link {
  text-decoration: none;
  color: white;
}
.sidebar {
  background-color: var(--kermony-office-bleu-1);
  height: 100%;
  padding: 24px 16px;
  color: var(--kermony-blanc);
  display: flex;
  flex-direction: column;
}
.sidebar .top img {
  width: 100%;
}

.separator {
  margin-top: 24px;
  margin-bottom: 24px;
  height: 1px;
  background-color: var(--kermony-office-beige);
}

.separator-bottom {
  margin-top: 16px;
  margin-bottom: 16px;
}

.nav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.nav .item {
  color: var(--kermony-blanc);
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 8px;
  gap: 8px;

  width: 240px;
  height: 48px;

  border-radius: 5px;
}

.nav .item.is-selected {
  background-color: var(--kermony-office-violet);
}

.bottom {
  display: grid;
  grid-auto-flow: column;
  height: 25px;
  align-items: center;
  font-size: 13px;
  text-align: center;
  font-weight: 200;
}
</style>
