import { createStore } from "vuex";
import HTTP from "@/axios";

// Load APIs
// import authApi from "@/api/auth";

let getLocalStorage = function () {
  let userData = localStorage.getItem("user");

  if (!userData) return null;

  return JSON.parse(userData);
};

const store = createStore({
  state: {
    header: {
      title: "",
      subtitle: "",
    },
    status: "guest",
    user: getLocalStorage(),
    avatarPresignedUrl: null,
  },
  mutations: {
    updateAvatarPresignedUrl(state, url) {
      state.avatarPresignedUrl = url;
    },
    updateHeader(state, headerData = { title: "", subtitle: "" }) {
      state.header = headerData;
    },
    authValidated(state, userData) {
      state.status = "logged";
      state.user = userData;
    },
    logout(state) {
      state.status = "guest";
      state.user = null;
    },
  },
  actions: {
    validateLogin({ commit }, userData) {
      return new Promise((resolve) => {
        if (userData.accessToken) {
          localStorage.setItem("user", JSON.stringify(userData));
          // https://auth0.com/blog/refresh-tokens-what-are-they-and-when-to-use-them/
          // Yes, you read that right. When we have refresh token rotation in place, we can store tokens in local storage or browser memory.
          HTTP.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${userData.accessToken}`;
        }

        // Update store with data
        commit("authValidated", userData);

        return resolve();
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        // Clean localStorage
        localStorage.removeItem("user");

        // Remove store data
        commit("logout");

        // Resolve promise
        return resolve();
      });
    },
  },
  getters: {
    // If user logged
    isLoggedIn: (state) => Boolean(state.user),
    // Get userId of the connected user
    userId: (state) => (state.user ? state.user.userId : null),
    // Get userId of the connected user
    userName: (state) => (state.user ? state.user.userName : null),
    // Get user status of user.
    userStatus: (state) => state.status,

    avatarPresignedUrl: (state) => state.avatarPresignedUrl,
  },
});

export default store;
