<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 16L12 8L20 16" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconChevronUp",
  props: ['stroke']
}
</script>

<style scoped>

</style>