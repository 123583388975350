<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 10.9999V6.99988C7 4.23845 9.23858 1.99988 12 1.99988C14.7614 1.99988 17 4.23845 17 6.99988V10.9999" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconLock",
  props: ['stroke']
}
</script>

<style scoped>

</style>