<template>
  <component v-bind:is="currentTabComponent" :stroke="color"></component>
</template>

<script>
function capitalizeFirstLetter(str) {
  // checks for null, undefined and empty string
  if (!str) return;
  return str.match("^[a-z]")
    ? str.charAt(0).toUpperCase() + str.substring(1)
    : str;
}

import IconThinFemale from "@/components/Icons/Thin/IconFemale";
import IconThinMale from "@/components/Icons/Thin/IconMale";
import IconThinAlertError from "@/components/Icons/Thin/IconAlertError";
import IconThinArrowDown from "@/components/Icons/Thin/IconArrowDown";
import IconThinArrowLeft1 from "@/components/Icons/Thin/IconArrowLeft1";
import IconThinArrowLeft from "@/components/Icons/Thin/IconArrowLeft";
import IconThinArrowRight1 from "@/components/Icons/Thin/IconArrowRight1";
import IconThinArrowRight from "@/components/Icons/Thin/IconArrowRight";
import IconThinArrowUp from "@/components/Icons/Thin/IconArrowUp";
import IconThinBadge from "@/components/Icons/Thin/IconBadge";
import IconThinBijoux from "@/components/Icons/Thin/IconBijoux";
import IconThinBloc from "@/components/Icons/Thin/IconBloc";
import IconThinBottle from "@/components/Icons/Thin/IconBottle";
import IconThinBranch from "@/components/Icons/Thin/IconBranch";
import IconThinCalendar from "@/components/Icons/Thin/IconCalendar";
import IconThinCheck from "@/components/Icons/Thin/IconCheck";
import IconThinChevronDown from "@/components/Icons/Thin/IconChevronDown";
import IconThinChevronLeft from "@/components/Icons/Thin/IconChevronLeft";
import IconThinChevronUp from "@/components/Icons/Thin/IconChevronUp";
import IconThinClose from "@/components/Icons/Thin/IconClose";
import IconThinCompany from "@/components/Icons/Thin/IconCompany";
import IconThinDashboard from "@/components/Icons/Thin/IconDashboard";
import IconThinDownload from "@/components/Icons/Thin/IconDownload";
import IconThinEmail from "@/components/Icons/Thin/IconEmail";
import IconThinEuro from "@/components/Icons/Thin/IconEuro";
import IconThinExpand from "@/components/Icons/Thin/IconExpand";
import IconThinFile from "@/components/Icons/Thin/IconFile";
import IconThinFinancial from "@/components/Icons/Thin/IconFinancial";
import IconThinFolder from "@/components/Icons/Thin/IconFolder";
import IconThinGrowth from "@/components/Icons/Thin/IconGrowth";
import IconThinHouse from "@/components/Icons/Thin/IconHouse";
import IconThinInbox from "@/components/Icons/Thin/IconInbox";
import IconThinInfo from "@/components/Icons/Thin/IconInfo";
import IconThinLock from "@/components/Icons/Thin/IconLock";
import IconThinLogo from "@/components/Icons/Thin/IconLogo";
import IconThinMenu from "@/components/Icons/Thin/IconMenu";
import IconThinMinus from "@/components/Icons/Thin/IconMinus";
import IconThinMoney from "@/components/Icons/Thin/IconMoney";
import IconThinMore from "@/components/Icons/Thin/IconMore";
import IconThinName from "@/components/Icons/Thin/IconName";
import IconThinPen from "@/components/Icons/Thin/IconPen";
import IconThinPercentage from "@/components/Icons/Thin/IconPercentage";
import IconThinPlus from "@/components/Icons/Thin/IconPlus";
import IconThinSearch from "@/components/Icons/Thin/IconSearch";
import IconThinTrash from "@/components/Icons/Thin/IconTrash";
import IconThinUmbrella from "@/components/Icons/Thin/IconUmbrella";
import IconThinUsers from "@/components/Icons/Thin/IconUsers";
import IconThinWallet from "@/components/Icons/Thin/IconWallet";
import IconThinWork from "@/components/Icons/Thin/IconWork";
import IconMediumFemale from "@/components/Icons/Medium/IconFemale";
import IconMediumMale from "@/components/Icons/Medium/IconMale";
import IconMediumAlertError from "@/components/Icons/Medium/IconAlertError";
import IconMediumArrowDown from "@/components/Icons/Medium/IconArrowDown";
import IconMediumArrowLeft1 from "@/components/Icons/Medium/IconArrowLeft1";
import IconMediumArrowLeft from "@/components/Icons/Medium/IconArrowLeft";
import IconMediumArrowRight1 from "@/components/Icons/Medium/IconArrowRight1";
import IconMediumArrowRight from "@/components/Icons/Medium/IconArrowRight";
import IconMediumArrowUp from "@/components/Icons/Medium/IconArrowUp";
import IconMediumBadge from "@/components/Icons/Medium/IconBadge";
import IconMediumBijoux from "@/components/Icons/Medium/IconBijoux";
import IconMediumBloc from "@/components/Icons/Medium/IconBloc";
import IconMediumBottle from "@/components/Icons/Medium/IconBottle";
import IconMediumBranch from "@/components/Icons/Medium/IconBranch";
import IconMediumCalendar from "@/components/Icons/Medium/IconCalendar";
import IconMediumCheck from "@/components/Icons/Medium/IconCheck";
import IconMediumChevronDown from "@/components/Icons/Medium/IconChevronDown";
import IconMediumChevronLeft from "@/components/Icons/Medium/IconChevronLeft";
import IconMediumChevronUp from "@/components/Icons/Medium/IconChevronUp";
import IconMediumClose from "@/components/Icons/Medium/IconClose";
import IconMediumDashboard from "@/components/Icons/Medium/IconDashboard";
import IconMediumDownload from "@/components/Icons/Medium/IconDownload";
import IconMediumEmail from "@/components/Icons/Medium/IconEmail";
import IconMediumEuro from "@/components/Icons/Medium/IconEuro";
import IconMediumExpand from "@/components/Icons/Medium/IconExpand";
import IconMediumFile from "@/components/Icons/Medium/IconFile";
import IconMediumFinancial from "@/components/Icons/Medium/IconFinancial";
import IconMediumFolder from "@/components/Icons/Medium/IconFolder";
import IconMediumGift from "@/components/Icons/Medium/IconGift";
import IconMediumGrowth from "@/components/Icons/Medium/IconGrowth";
import IconMediumHouse from "@/components/Icons/Medium/IconHouse";
import IconMediumInbox from "@/components/Icons/Medium/IconInbox";
import IconMediumInfo from "@/components/Icons/Medium/IconInfo";
import IconMediumLock from "@/components/Icons/Medium/IconLock";
import IconMediumLogo from "@/components/Icons/Medium/IconLogo";
import IconMediumMenu from "@/components/Icons/Medium/IconMenu";
import IconMediumMinus from "@/components/Icons/Medium/IconMinus";
import IconMediumMoney from "@/components/Icons/Medium/IconMoney";
import IconMediumMore from "@/components/Icons/Medium/IconMore";
import IconMediumPen from "@/components/Icons/Medium/IconPen";
import IconMediumPercentage from "@/components/Icons/Medium/IconPercentage";
import IconMediumPlus from "@/components/Icons/Medium/IconPlus";
import IconMediumSearch from "@/components/Icons/Medium/IconSearch";
import IconMediumTrash from "@/components/Icons/Medium/IconTrash";
import IconMediumUmbrella from "@/components/Icons/Medium/IconUmbrella";
import IconMediumUsers from "@/components/Icons/Medium/IconUsers";
import IconMediumWallet from "@/components/Icons/Medium/IconWallet";
import IconMediumWork from "@/components/Icons/Medium/IconWork";

export default {
  name: "Icon",
  props: ["name", "size", "color"],
  components: {
    IconThinFemale,
    IconThinMale,
    IconThinAlertError,
    IconThinArrowDown,
    IconThinArrowLeft1,
    IconThinArrowLeft,
    IconThinArrowRight1,
    IconThinArrowRight,
    IconThinArrowUp,
    IconThinBadge,
    IconThinBijoux,
    IconThinBloc,
    IconThinBottle,
    IconThinBranch,
    IconThinCalendar,
    IconThinCheck,
    IconThinChevronDown,
    IconThinChevronLeft,
    IconThinChevronUp,
    IconThinClose,
    IconThinCompany,
    IconThinDashboard,
    IconThinDownload,
    IconThinEmail,
    IconThinEuro,
    IconThinExpand,
    IconThinFile,
    IconThinFinancial,
    IconThinFolder,
    IconThinGrowth,
    IconThinHouse,
    IconThinInbox,
    IconThinInfo,
    IconThinLock,
    IconThinLogo,
    IconThinMenu,
    IconThinMinus,
    IconThinMoney,
    IconThinMore,
    IconThinName,
    IconThinPen,
    IconThinPercentage,
    IconThinPlus,
    IconThinSearch,
    IconThinTrash,
    IconThinUmbrella,
    IconThinUsers,
    IconThinWallet,
    IconThinWork,
    IconMediumFemale,
    IconMediumMale,
    IconMediumAlertError,
    IconMediumArrowDown,
    IconMediumArrowLeft1,
    IconMediumArrowLeft,
    IconMediumArrowRight1,
    IconMediumArrowRight,
    IconMediumArrowUp,
    IconMediumBadge,
    IconMediumBijoux,
    IconMediumBloc,
    IconMediumBottle,
    IconMediumBranch,
    IconMediumCalendar,
    IconMediumCheck,
    IconMediumChevronDown,
    IconMediumChevronLeft,
    IconMediumChevronUp,
    IconMediumClose,
    IconMediumDashboard,
    IconMediumDownload,
    IconMediumEmail,
    IconMediumEuro,
    IconMediumExpand,
    IconMediumFile,
    IconMediumFinancial,
    IconMediumFolder,
    IconMediumGift,
    IconMediumGrowth,
    IconMediumHouse,
    IconMediumInbox,
    IconMediumInfo,
    IconMediumLock,
    IconMediumLogo,
    IconMediumMenu,
    IconMediumMinus,
    IconMediumMoney,
    IconMediumMore,
    IconMediumPen,
    IconMediumPercentage,
    IconMediumPlus,
    IconMediumSearch,
    IconMediumTrash,
    IconMediumUmbrella,
    IconMediumUsers,
    IconMediumWallet,
    IconMediumWork,
  },
  computed: {
    currentTabComponent: function () {
      return (
        "Icon" +
        capitalizeFirstLetter(this.size) +
        capitalizeFirstLetter(this.name)
      );
    },
  },
};
</script>
