<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 14V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 3V17M12 17L7 11.5555M12 17L17 11.5556" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconDownload",
  props: ['stroke']
}
</script>

<style scoped>

</style>