<template>
  <svg
    :stroke="stroke"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :fill="stroke"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 21H20C21.1046 21 22 20.1046 22 19V8C22 6.89543 21.1046 6 20 6H11L9.29687 3.4453C9.1114 3.1671 8.79917 3 8.46482 3H4C2.89543 3 2 3.89543 2 5V19C2 20.1046 2.89543 21 4 21Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconFolder",
  props: ["stroke"],
};
</script>

<style scoped></style>
