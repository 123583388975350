<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.5 8.5C18.5 12.0899 15.5899 15 12 15C8.41015 15 5.5 12.0899 5.5 8.5C5.5 4.91015 8.41015 2 12 2C15.5899 2 18.5 4.91015 18.5 8.5Z" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.5 19H16.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 22V15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconFemale",
  props: ['stroke']
}
</script>

<style scoped>

</style>