import { createApp } from "vue";

import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import vSelect from "vue-select";
import VueHotjar from "vue-hotjar-next"
import VueGtag from "vue-gtag-next";

import {
  Tooltip,
  Collapse,
  CollapsePanel,
  Modal,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  Menu,
  Alert,
  Button,
  Upload,
  Spin,
  UploadDragger,
  Popconfirm,
  Input,
  Form,
  FormItem,
  Checkbox,
  Select,
  SelectOption,
  RadioGroup,
  Radio,
  InputNumber,
  DatePicker,
  Tabs,
  TabPane,
  Space,
  Table,
  ConfigProvider,
  Row,
  Col,
} from "ant-design-vue";

// import "ant-design-vue/dist/antd.css"; // or 'ant-design-vue/dist/antd.less'
import "ant-design-vue/dist/antd.less";

const app = createApp(App);
app.use(Tooltip);
app.use(Collapse);
app.use(CollapsePanel);
app.use(Modal);
app.use(Breadcrumb);
app.use(BreadcrumbItem);
app.use(Dropdown);
app.use(Menu);
app.use(Alert);
app.use(Button);
app.use(Upload);
app.use(Spin);
app.use(UploadDragger);
app.use(Popconfirm);
app.use(Input);
app.use(Form);
app.use(FormItem);
app.use(Checkbox);
app.use(Select);
app.use(SelectOption);
app.use(RadioGroup);
app.use(Radio);
app.use(InputNumber);
app.use(DatePicker);
app.use(Tabs);
app.use(TabPane);
app.use(Space);
app.use(Table);
app.use(ConfigProvider);
app.use(Row);
app.use(Col);

app.use(store);
app.use(router);

app.component("v-select", vSelect);
// app.use(VuelidatePlugin);

// Init Hotjar
app.use(VueHotjar, {
  id: 3354822,
  isProduction: (process.env.NODE_ENV === "production" ? true : false),
  snippetVersion: 6
});

app.use(VueGtag, {
  property: {
    id: "GTM-W2MX4MF6"
  }
});

app.mount("#app");
