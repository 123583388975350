import HTTP from "@/axios";

export default {
  fetchCustomerData() {
    return new Promise((resolve, reject) => {
      HTTP.get(`customer`)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setAdmin(id) {
    return new Promise((resolve, reject) => {
      HTTP.post(`customer/${id}/set_admin`)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAvatarPresignedUrl(userId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`customer/avatar/${userId}`)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
