<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 13H20V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V13Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 9H22V13H2V9Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 5V22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 5.5C12 3.567 10.433 2 8.5 2C6.567 2 5 3.567 5 5.5C5 7.433 6.567 9 8.5 9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5 9C17.433 9 19 7.433 19 5.5C19 3.567 17.433 2 15.5 2C13.567 2 12 3.567 12 5.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconGift",
  props: ['stroke']
}
</script>

<style scoped>

</style>