<template>
  <a-config-provider :locale="locale">
    <div v-if="requiresAuth" class="main_layout">
      <Sidebar />
      <Layout :requiresAuth="requiresAuth">
        <router-view />
      </Layout>
    </div>
    <router-view v-else />
    <MobileIsNotSupported v-if="!requiresAuth" />
  </a-config-provider>
</template>

<script>
import "@/assets/styles/global.min.css";

import Sidebar from "@/components/Sidebar.vue";
import Layout from "@/components/Layout";
import MobileIsNotSupported from "@/views/App/MobileIsNotSupported";
import frFR from "ant-design-vue/es/locale/fr_FR";
import dayjs from "dayjs";
import "dayjs/locale/fr";
dayjs.locale("fr");
export default {
  name: "App",
  components: { MobileIsNotSupported, Layout, Sidebar },

  data() {
    return {
      locale: frFR,
      requiresAuth: false,
    };
  },

  watch: {
    $route: "onRouteUpdated",
  },

  methods: {
    onRouteUpdated() {
      this.$nextTick(() => {
        this.requiresAuth = !!this.$route.meta.requiresAuth;
      });
    },
  },
};
</script>
<style>
.grecaptcha-badge {
  visibility: hidden;
}
.layout-mobile-not-supported {
  display: none;
}

.visible-mobile {
  display: none;
}
@media (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
  .visible-mobile {
    display: initial !important;
  }
}

.mobile-scroll-container {
  max-width: calc(100vw - 272px);
}

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  body,
  html {
    background-color: var(--kermony-office-bleu-1) !important;
  }
  .main_layout {
    /*
    grid-template-columns: initial !important;
    grid-auto-rows: 120px 1fr;
     */
  }
  .wrapper-btn-mobile-full-width .k-btn {
    width: 100% !important;
  }

  .right-side {
    width: 100%;
  }
  .mobile-scroll-container {
    max-width: initial !important;
  }

  .right-side > .mobile-scroll-container > .header {
    height: initial !important;
    margin-bottom: 16px !important;
  }

  .right-side > .mobile-scroll-container > .header > a {
    display: none;
  }

  .right-side > .mobile-scroll-container > .header .left {
    margin-left: 16px !important;
    margin-right: 16px !important;
    margin-top: 0px !important;
  }
  .right-side > .mobile-scroll-container > .header .left h1,
  .right-side > .mobile-scroll-container > .header .left h2 {
    color: white;
  }

  .mobile-scroll-container {
    overflow: auto;
    /*height: calc(100vh - 120px);*/
  }

  .right-side {
    background-color: var(--kermony-office-bleu-1) !important;
    /* This scrollbar replace scrollbar of .right-side .container  */
    /*overflow: auto;*/
    height: 100%;
  }

  .grid {
    grid-template-columns: initial !important;
    gap: 8px !important;
  }

  .right-side .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
    height: initial !important;
    overflow: initial !important;
  }

  .container-select-user {
    flex-direction: column;
    gap: 16px;
  }

  .container-select-user .ant-select.ant-select-single {
    width: 100%;
  }

  /*
    Sidebar
   */

  .right-side {
    position: absolute;
    transition: 500ms transform cubic-bezier(0, 0.12, 0.14, 1);
  }
  .sidebar {
    /*display: none !important;*/
    opacity: 0;
    transition: 500ms opacity cubic-bezier(0, 0.68, 0.73, 1.1);
  }

  .sidebar-is-open .sidebar {
    /*display: flex !important;*/
    opacity: 1;
  }

  .sidebar-is-open .right-side {
    transform: translateX(272px);
    box-shadow: inset -1px 0px 10px 0px rgb(0 0 0 / 35%);
  }

  .main_layout {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .sidebar {
    position: fixed !important;
  }

  .sidebar .top,
  .sidebar .separator {
    display: none !important;
  }

  .header-mobile {
    display: flex !important;
  }

  /*
  End sidebar
   */

  .action {
    display: initial !important;
  }
}

.header-mobile {
  display: none;
}

/** Surface Pro media query **/
@media (min--moz-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2),
  (-webkit-min-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5),
  (min-resolution: 144dpi),
  (min-resolution: 1.5dppx) and (min-width: 1500px) and (max-width: 2000px) and @-ms-viewport {
  /*
  .main_layout {
    display: grid !important;
  }

   */
  .layout-login {
    display: flex !important;
  }
  .layout-mobile-not-supported {
    display: none !important;
  }
}

body {
  margin: 0;
  padding: 0;

  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
}
#app {
  height: 100%;
  width: 100%;
}
:root {
  /* Color styles */
  /*--kermony-bleu-1: rgba(0, 55, 112, 1);*/
  --kermony-bleu-2: rgba(145, 155, 192, 1);
  /*--kermony-bleu-3: rgba(206, 215, 239, 1);*/
  --kermony-gris-1: rgba(207, 210, 212, 1);
  --kermony-gris-2: rgba(119, 124, 127, 1);
  --kermony-noir: rgba(0, 0, 0, 1);
  --kermony-blanc: rgba(255, 255, 255, 1);
  --kermony-office-violet: rgba(109, 45, 104, 1);
  --kermony-office-rose: rgba(172, 35, 105, 1);
  --kermony-office-bleu-1: rgba(33, 36, 78, 1);
  --kermony-office-bleu-2: rgba(113, 200, 218, 1);
  --kermony-office-beige: rgba(248, 244, 245, 0.5);
  /* Effect styles */
  --c-t-a-shadow: 0px 2px 8px rgba(109, 45, 104, 0.3);
  --card-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
}

.color-kermony-white {
  color: var(--kermony-blanc) !important;
}

.color-kermony-blue-1 {
  color: var(--kermony-office-bleu-1) !important;
}

.color-kermony-gray-2 {
  color: var(--kermony-gris-2) !important;
}

.main_layout {
  display: grid;
  grid-template-columns: 272px 1fr;
  height: 100%;
}
.tooltip-inner {
  max-width: 350px;
}

.ant-dropdown-content {
  font-weight: 610 !important;
  font-size: 15px !important;
  line-height: 19px !important;
  text-align: center !important;
  letter-spacing: 1.25px !important;
  text-transform: uppercase !important;
  color: var(--kermony-office-bleu-1) !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 12px rgb(33 36 78 / 10%) !important;
  border-radius: 5px !important;
}

.ant-dropdown-menu {
  padding: 16px 0 !important;
}

.ant-dropdown-menu-item {
  padding: 8px 24px !important;
}

.ant-tabs-tab {
  padding: 8px 16px !important;
}

.ant-tabs {
  /*padding-top: 16px !important;*/
}

/*
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--kermony-office-rose) !important;
}

.ant-tabs-ink-bar {
  background: var(--kermony-office-rose) !important;
}

 */

.ant-tabs {
  color: var(--kermony-gris-2);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: var(--kermony-office-violet) !important;
}
.ant-tabs-tab:hover {
  color: rgba(172, 35, 105, 0.85) !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 2px solid var(--kermony-gris-1) !important;
}
.ant-tabs-nav-list {
  font-family: "Weissenhof Grotesk";
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.ant-tabs-content-holder {
  padding: 0px 16px !important;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  background-color: white !important;
  padding-top: 16px !important;
}

.big-header.ant-tabs > .ant-tabs-nav,
.big-header.ant-tabs > div > .ant-tabs-nav {
  padding-top: 24px !important;
}
.big-header .right-tab-container {
  height: 57px;
}

.aligner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aligner-v {
  display: flex;
  align-items: center;
}

.aligner-h {
  display: flex;
  justify-content: center;
}

.ant-select-selector {
  border-radius: 5px !important;
}
.ant-form-item-control-input-content input,
.ant-input-number,
.ant-input,
.ant-picker {
  border-radius: 5px !important;
}

.ant-input-number-handler-wrap {
  border-radius: 0 5px 5px 0 !important;
}

/*

.ant-picker-today-btn {
  color: var(--kermony-office-rose) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--kermony-office-rose) !important;
  border-color: var(--kermony-office-rose) !important;
}

.ant-picker:hover,
.ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input-number:hover,
.ant-input:hover {
  border-color: var(--kermony-office-rose) !important;
}

.ant-picker-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input:focus,
.ant-input-focused {
  border-color: var(--kermony-office-rose) !important;
  box-shadow: 0 0 0 2px rgba(172, 35, 105, 20%) !important;
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: var(--kermony-office-rose) !important;
  border-color: var(--kermony-office-rose) !important;
}
 */

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid {
  display: grid;
  gap: 16px;
  /*grid-template-columns: 1fr 1fr;*/
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.position-relative {
  position: relative;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.text-right {
  text-align: right;
}

/*
.ant-btn.ant-btn-primary {
  background: var(--kermony-office-rose);
  color: var(--kermony-blanc);
  border: none;
}

.ant-btn.ant-btn-primary:disabled {
  background: var(--kermony-gris-1);
}
.ant-btn.ant-btn-primary:hover {
  background: var(--kermony-office-rose);
  box-shadow: 0px 2px 8px rgba(109, 45, 104, 0.3);
}

.ant-btn.ant-btn-primary:disabled:hover {
  box-shadow: 0px 2px 8px rgb(192, 194, 196);
}
.ant-btn.ant-btn-primary:focus {
  background: var(--kermony-office-violet);
}

 */

.position-absolute {
  position: absolute;
}

.position-absolute-important {
  position: absolute !important;
}

.w-100 {
  width: 100%;
}

.ant-modal-header,
.ant-modal-footer {
  border-color: transparent !important;
}

.ant-btn {
  border-radius: 5px !important;
}

.ant-input-number {
  width: 100% !important;
}

.ant-form-item-label > label {
  font-family: "Weissenhof Grotesk" !important;
  font-weight: 280 !important;
  font-size: 17px !important;
  color: var(--kermony-office-bleu-1) !important;
}
.ant-form-item-label {
  padding-bottom: 4px !important;
}
label.ant-form-item-required {
  flex-direction: row-reverse;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-left: 4px !important;
  margin-right: initial !important;
}

.ant-modal-content {
  border-radius: 5px !important;
}
.ant-modal-header {
  border-radius: 5px 5px 0 0 !important;
}

.ant-modal-footer {
  padding: 0px 32px 32px 32px !important;
}

.ant-alert {
  border-radius: 5px !important;
}

.ant-alert-info {
  background-color: #dde5fd !important;
  border: 1px solid #2d316d !important;
}
.ant-alert-info .ant-alert-message {
  color: #2d316d !important;
}
.ant-alert-info .ant-alert-icon {
  color: #2d316d !important;
}
</style>
