<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 3V21" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 21H3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 16L12.25 10.75L15.75 14.25L21 9" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconGrowth",
  props: ['stroke']
}
</script>

<style scoped>

</style>