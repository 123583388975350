<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 6H21M5 6V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V6M8 6V4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 11V17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 11V17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconTrash",
  props: ['stroke']
}
</script>

<style scoped>

</style>