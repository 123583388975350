<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V6Z" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 10H21" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 2V6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 2V6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconCalendar",
  props: ['stroke']
}
</script>

<style scoped>

</style>