<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9994 3C17.1848 3 21.4977 6.94668 21.9994 12C21.3937 11.8436 20.6262 11.8417 19.9008 11.9104C17.9743 12.0926 16.3685 13.1073 16.107 14.2782M11.9994 3C6.8141 3 2.55055 6.94668 2.04883 12M11.9994 3V2M2.07995 12C2.68572 11.8436 3.45324 11.8417 4.17857 11.9104C6.10514 12.0926 7.71093 13.1073 7.97242 14.2782M7.99945 14C8.54856 12.8348 10.2578 12 11.9994 12M11.9994 12C13.7411 12 15.4503 12.8348 15.9994 14M11.9994 12V19.5C11.9994 20.8807 10.8802 22 9.49945 22C8.11874 22 6.99945 20.8807 6.99945 19.5V19M12.1671 3C14.5447 5.62038 15.9994 9.13579 15.9994 13.0001M11.8317 3C9.4541 5.62037 7.99946 9.13572 7.99946 13" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconUmbrella",
  props: ['stroke']
}
</script>

<style scoped>

</style>