<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.33203 5.99996C1.33203 5.26358 1.92898 4.66663 2.66536 4.66663H13.332C14.0684 4.66663 14.6654 5.26358 14.6654 5.99996V13.3333C14.6654 14.0697 14.0684 14.6666 13.332 14.6666H2.66536C1.92898 14.6666 1.33203 14.0697 1.33203 13.3333V5.99996Z" stroke="#6D2D68" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.6654 4.66671V2.66671C10.6654 1.93033 10.0684 1.33337 9.33203 1.33337H6.66536C5.92898 1.33337 5.33203 1.93033 5.33203 2.66671V4.66671" stroke="#6D2D68" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.6654 8L8.26017 9.28107C8.08757 9.31553 7.90983 9.31553 7.73723 9.28107L1.33203 8" stroke="#6D2D68" stroke-width="0.67" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconCompany",
  props: ['stroke']
}
</script>

<style scoped>

</style>