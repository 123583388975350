<template>
  <svg
    width="24"
    stroke="white"
    height="24"
    viewBox="0 0 24 24"
    :fill="stroke"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 22H18C19.1046 22 20 21.1046 20 20V9.82843C20 9.29799 19.7893 8.78929 19.4142 8.41421L13.5858 2.58579C13.2107 2.21071 12.702 2 12.1716 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M13 2.5V9H19" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 17H15" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 13H15" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 9H9" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: "IconFile",
  props: ["stroke"],
};
</script>

<style scoped></style>
