<template>
  <svg
    :stroke="stroke"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :fill="stroke"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12H3M3 12L10 5M3 12L10 19"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrowLeft",
  props: ["stroke"],
};
</script>

<style scoped></style>
