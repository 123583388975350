<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V13" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 11.5L17.5 3.49998C18.3284 2.67155 19.6716 2.67155 20.5 3.49998C21.3284 4.32841 21.3284 5.67155 20.5 6.49998L12.5 14.5L8 16L9.5 11.5Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconPen",
  props: ['stroke']
}
</script>

<style scoped>

</style>