<template>
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1.0, viewport-fit=cover"
  />

  <div class="layout-mobile-not-supported">
    <div class="card-mobile-not-supported">
      <LogoWhite />
      <Heading5>La version mobile est en cours de développement</Heading5>
    </div>
  </div>
</template>

<script>
import LogoWhite from "@/views/Auth/Login/LogoWhite";
import Heading5 from "@/components/Texts/Heading5";
export default {
  name: "MobileIsNotSupported",
  components: { Heading5, LogoWhite },
};
</script>

<style scoped>
.layout-mobile-not-supported {
  height: 100%;
  width: 100%;
  background: url(/filigrane.png) #fbf9fa;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
}

.layout-mobile-not-supported .card-mobile-not-supported {
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 24px;

  position: absolute;
  /*height: 500px;*/
  /*min-height: 400px;*/

  /* Kermony/Blanc */

  background: #ffffff;
  /* Card shadow */

  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 5px;
  text-align: center;
}
</style>
