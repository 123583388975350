// import Vue from "vue";
// import VueRouter from "vue-router";
import store from "@/store/index";
import { trackRouter } from "vue-gtag-next";

// Vue.use(VueRouter);
import { createWebHistory, createRouter } from "vue-router";
// import Home from "@/views/Home.vue";
// import About from "@/views/About.vue";

const routes = [
  {
    path: "/",
    name: "dashboard",
    meta: { requiresAuth: true },
    component: () => import("../views/App/MyDashboard/Index.vue"),
  },
  {
    path: "/family",
    name: "family",
    meta: { requiresAuth: true },
    component: () => import("../views/App/Family/Index.vue"),
    children: [
      {
        path: "view",
        name: "family.view",
        component: () => import("../views/App/Family/View.vue"),
      },
      {
        path: "add",
        name: "family.add",
        component: () => import("../views/App/Family/Add.vue"),
      },
      {
        path: "list",
        name: "family.list",
        component: () => import("../views/App/Family/List.vue"),
      },
    ],
  },
  {
    path: "/transmission",
    name: "transmission",
    meta: { requiresAuth: true },
    component: () => import("../views/App/Transmission/Transmission.vue"),
  },
  {
    path: "/valuable-objects",
    name: "valuable-objects",
    meta: { requiresAuth: true },
    component: () => import("../views/App/ValuableObjects/ValuableObjects.vue"),
  },
  {
    path: "/coffre-fort",
    name: "coffre-fort",
    meta: { requiresAuth: true },
    component: () => import("../views/App/CoffreFort/CoffreFort.vue"),
  },
  {
    path: "/kermony-et-moi",
    name: "kermony-et-moi",
    component: () => import("../views/App/KermonyServices/KermonyServices.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-financial-assets",
    name: "my-financial-assets",
    component: () =>
      import("../views/App/MyFinancialAssets/MyFinancialAssets.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "my-account",
    component: () => import("../views/App/MyAccount/MyAccount.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/coming-soon",
    name: "coming-soon",
    component: () => import("../views/App/ComingSoon.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Auth/Login.vue"),
  },
  {
    path: "/admin-authentication/:userToken",
    name: "admin-authentication",
    component: () => import("../views/Auth/AdminLogin.vue"),
  },
  {
    path: "/register/:registrationToken",
    name: "register",
    component: () => import("../views/Auth/Register.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // If page has meta requiresAuth, check if user is logged
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) return next();

    // Redirect to login
    return next("/login");
  }
  return next();
});

trackRouter(router);

// export default router;

// router.beforeEach((to, from, next) => {
// 	// If page has meta requiresAuth, check if user is logged
// 	if (to.matched.some((record) => record.meta.requiresAuth)) {
// 		if (store.getters.isLoggedIn)
// 			return next();

// 		// Redirect to login
// 		console.log("[ROUTER] User not logged trying visit page:", to.fullPath, "redirect to login.");
// 		return next("/login");
// 	}
// 	return next();
// });

export default router;
