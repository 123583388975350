<template>
  <div class="header-mobile">
    <button @touchend="toggleSidebar" class="button-menu">
      <Icon size="medium" name="menu" color="white"></Icon>
    </button>

    <Icon
      size="thin"
      name="logo"
      color="white"
      style="width: 50px; height: 50px"
    ></Icon>

    <router-link :to="{ name: 'my-account' }">
      <Avatar :src="getUrl" size="40" />
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/components/Icons/Icon";
import Avatar from "@/components/Avatars/Avatar";

export default {
  name: "HeaderMobile",
  components: { Avatar, Icon },

  watch: {
    $route: "currentRoute",
  },

  mounted() {
    document.querySelector(".right-side").addEventListener("touchend", () => {
      this.closeSidebar();
    });
  },

  computed: {
    ...mapState(["user", "status", "header", "avatarPresignedUrl"]),
    getUrl() {
      return this.avatarPresignedUrl;
    },
  },

  methods: {
    closeSidebar() {
      document
        .querySelector(".main_layout")
        .classList.remove("sidebar-is-open");
    },
    toggleSidebar(event) {
      event.stopPropagation();
      document
        .querySelector(".main_layout")
        .classList.toggle("sidebar-is-open");
    },
    currentRoute() {
      this.closeSidebar();
    },
  },
};
</script>

<style type="text/css" scoped>
.button-menu {
  width: 40px;
  height: 40px;
  background: #ac2369;
  box-shadow: 0px 2px 8px rgba(109, 45, 104, 0.3);
  border-radius: 5px;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
}

.header-mobile {
  height: 120px;
  flex-direction: revert;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
}
</style>
