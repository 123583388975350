<template>
  <div class="header">
    <div class="left">
      <h1 v-if="typeof header.title === 'string'">{{ header.title }}</h1>
      <h1 v-else>
        <component :is="header.title" />
      </h1>
      <h2 v-if="typeof header.subtitle === 'string'">{{ header.subtitle }}</h2>
      <h2 v-else>
        <component :is="header.subtitle" />
      </h2>
      <!-- <h2 v-if="typeof header.subLegend === 'string'">{{ header.subLegend }}</h2> -->
    </div>

    <router-link :to="{ name: 'my-account' }">
      <div
        class="account"
        :style="
          routeName === 'my-account'
            ? {
                background: 'var(--kermony-office-violet)',
              }
            : {}
        "
      >
        <span
          class="label"
          :style="
            routeName === 'my-account'
              ? {
                  color: 'var(--kermony-blanc)',
                }
              : {}
          "
          >Mon compte</span
        >
        <img :src="getUrl" alt="" />
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Header",
  data() {
    return {
      routeName: this.$route.name,
    };
  },

  watch: {
    $route: "currentRoute",
  },
  computed: {
    ...mapState(["user", "status", "header", "avatarPresignedUrl"]),
    getUrl() {
      return this.avatarPresignedUrl;
    },
  },

  methods: {
    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name;
      });
    },

    getAvatarLetter() {
      if (this.user && this.user.customerName)
        return this.user.customerName.charAt(0).toUpperCase();

      return "?";
    },

    isActiveMenu: function (itemName) {
      const routeName = this.routeName;

      if (!routeName) return false;

      return { "menu-item-here": routeName.includes(itemName) };
    },
  },
};
</script>

<style type="text/css" scoped>
.header {
  height: 144px;
  display: flex;
  /*background: linear-gradient(45deg, #00f 12.5%, #fff 12.5%, #fff 37.5%, #00f 37.5%, #00f 62.5%, #fff 62.5%, #fff 87.5%, #00f 87.5%);*/
}

.header .left {
  margin-left: 25px;
  margin-top: 24px;
  flex-grow: 1;
}

.header .left h1 {
  font-weight: 280;
  font-size: 36px;
  line-height: 47px;
  color: var(--kermony-office-bleu-1);
  margin: 0 0 4px;
}

.header .left h2 {
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  color: var(--kermony-gris-2);
  margin: 0;
}

.header .account {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12.5px 16px;
  gap: 16px;

  width: 194px;
  height: 73px;

  border-radius: 5px;
  margin-right: 24px;
  margin-top: 19px;
}

.header .account img {
  width: 48px;
  height: 48px;

  border-radius: 8px;
}

.header .account .label {
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  color: var(--kermony-office-bleu-1);
}
</style>
