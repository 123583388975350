<template>
  <span class="Heading5"><slot></slot></span>
</template>

<script>
export default {
  name: "Heading5",
};
</script>

<style scoped>
.Heading5 {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 25px;
  line-height: 32px;
  color: var(--kermony-office-bleu-1);
}
</style>
