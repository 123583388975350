<template>
  <svg
    :stroke="stroke"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :fill="stroke"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="22.5"
      height="22.5"
      rx="3.25"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: "IconBloc",
  props: ["stroke"],
};
</script>

<style scoped></style>
