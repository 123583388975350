<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 5H14V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H11C10.7348 2 10.4804 2.10536 10.2929 2.29289C10.1054 2.48043 10 2.73478 10 3V5Z" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 3.5C14 5.126 14.507 6.712 15.45 8.037L15.5 8.107C16.4758 9.4777 17.0001 11.1184 17 12.801V19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21H9C8.46957 21 7.96086 20.7893 7.58579 20.4142C7.21071 20.0391 7 19.5304 7 19V12.8C7 11.118 7.524 9.478 8.5 8.107L8.55 8.037C9.49317 6.71215 10 5.12629 10 3.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.00391 14.803C7.40281 14.6306 7.74741 14.353 8.00091 14C8.22808 13.6835 8.52888 13.4271 8.87733 13.2529C9.22578 13.0787 9.61141 12.9919 10.0009 13C10.3904 12.9919 10.776 13.0787 11.1245 13.2529C11.4729 13.4271 11.7737 13.6835 12.0009 14C12.2281 14.3165 12.5289 14.573 12.8773 14.7472C13.2258 14.9214 13.6114 15.0082 14.0009 15C14.3904 15.0082 14.776 14.9214 15.1245 14.7472C15.4729 14.573 15.7737 14.3165 16.0009 14C16.255 13.6459 16.6007 13.3676 17.0009 13.195" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconBottle",
  props: ['stroke']
}
</script>

<style scoped>

</style>