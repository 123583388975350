<template>
  <img
    :src="localSrc"
    class="avatar"
    :style="{ width: `${size}px`, height: `${size}px` }"
  />
</template>

<script>
export default {
  name: "Avatar",
  props: ["src", "size"],
  data() {
    return {
      localSrc: "",
    };
  },
  watch: {
    src: function (newSrc) {
      this.computeSrc(newSrc);
    },
  },
  mounted() {
    this.computeSrc(this.src);
  },
  methods: {
    computeSrc(src) {
      Promise.resolve(src).then((value) => {
        this.localSrc = value;
      });
    },
  },
};
</script>

<style scoped>
.avatar {
  border-radius: 5px;
  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  object-fit: cover;
}
</style>
