<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="3.5"/>
</svg>

</template>

<script>
export default {
  name: "IconBloc",
  props: ['stroke']
}
</script>

<style scoped>

</style>