<template>
  <div class="right-side">
    <HeaderMobile />

    <div class="mobile-scroll-container">
      <Header v-if="requiresAuth" />
      <div class="container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import HeaderMobile from "@/components/HeaderMobile";
import CustomerApi from "@/api/customer";
export default {
  name: "Layout",
  components: { HeaderMobile, Header },
  props: ["requiresAuth"],
  mounted() {
    this.getAvatar();
  },

  methods: {
    async getAvatar() {
      const response = await CustomerApi.getAvatarPresignedUrl(
        this.$store.state.user.customerId
      );
      const avatarUrl = response.avatar;
      this.$store.commit("updateAvatarPresignedUrl", avatarUrl);

      const urlParams = new URLSearchParams(avatarUrl);

      // Provides the time period, in seconds
      let expires = Number(urlParams.get("X-Amz-Expires"));
      // Prevent polling to quickly
      if (expires < 600) {
        expires = 600;
      }

      const getNewPresignedUrlAfter = expires / 2;
      setTimeout(() => {
        this.getAvatar();
      }, getNewPresignedUrlAfter * 1000);
    },
  },
};
</script>

<style scoped>
.right-side {
  background-color: #fbf9fa;
  display: grid;
  grid-template-rows: auto 1fr;
}
.right-side .container {
  /*
  margin-left: 25px;
  margin-right: 25px;
   */

  padding-left: 25px;
  padding-right: 25px;
  overflow: auto;
  height: calc(100vh - 144px);
  /*background-image: linear-gradient(45deg, #777 25%, transparent 25%, transparent 75%, #777 75%), linear-gradient(45deg, #777 25%, transparent 25%, transparent 75%, #777 75%);*/
}
</style>
