<template>
<svg :stroke="stroke" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 14V17" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 14V17" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 10V21" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19 10V21" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 21H21" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 10H21" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19 7.00011L12 3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 7.00011L12 3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: "IconFinancial",
  props: ['stroke']
}
</script>

<style scoped>

</style>